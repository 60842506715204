.bordered-table {
	border-collapse: collapse;
	// border-spacing: 0;
	// width: 100%;

	// tr {}

	td {
		border-left: 1px solid black;
		border-top: 1px solid black;
	}
}