.bar-frame {
	width: 100%;
	height: 73px;
	border-bottom: 1px solid #EAECF0;
	display: flex;
	align-items: center;



	.dashboard {

		color: #475467;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		/* 142.857% */
	}

	.lasso {
		color: #101828;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;

	}

	.breadcrumbs {
		display: flex;
		margin-left: 40px;

		div,
		img {
			margin-right: 10px;
		}

		margin-right: auto;
	}

	.right-bar {
		width: 80px;
		display: flex;
		justify-content: space-between;
	}
}


.navigation {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #EAECF0;
	margin-bottom: 20px;
	margin-left: 40px;
	margin-right: 50px;
	display: flex;
	align-items: center;

	.navigation-cell {
		height: 100%;
		user-select: none;
		padding-right: 30px;
		padding-left: 30px;
		margin-left: 10px;
		color: #101828;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;

		&:first-child {
			margin-left: 0px;

		}

		/* 150% */
		&.selected {
			color: #175CD3;
			// border-radius: 6px;
			border-bottom: #175CD3 2px solid;
			// background: #F9FAFB;
		}

		&:hover {
			border-bottom: #175CD3 2px solid;
		}
	}
}