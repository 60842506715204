.table-head {

	color: #101828;
	text-align: justify;

	/* LS_Body 2 - SemiBold 14 */
	font-family: "Hanken Grotesk";
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
	/* 19.6px */
}

.table-text {
	color: #101828;
	text-align: justify;
	overflow-x: hidden;
	/* set ellipsis for overflow text */
	white-space: nowrap;
	text-overflow: ellipsis;

	/* LS_Body 2 - Regular 14 */
	font-family: "Hanken Grotesk";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	/* 19.6px */
}


.table-row {
	display: flex;
	position: relative;

	// Initially hide buttons
	.only-hover-edit {
		display: none;
		position: absolute;
		right: 74px;
		bottom: 10px;
		width: 36px;
		height: 36px;
		justify-content: center;
		align-items: center;
		border-radius: 5.25px;
		border: 1.25px solid var(--Gray-300, #D0D5DD);
		background: var(--Base-White, #FFF);
		box-shadow: 0px 0.778px 1.556px 0px rgba(16, 24, 40, 0.05);
	}

	.only-hover-trash {
		display: none;
		position: absolute;
		right: 24px;
		bottom: 10px;

		padding: 6px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		border: 1px solid var(--Error-600, #D92D20);
		background: var(--Error-600, #D92D20);

		/* Shadow/xs */
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}

	// Show buttons when hoverDiv is hovered
	&:hover .only-hover-trash {
		display: flex;
	}

	&:hover .only-hover-edit {
		display: flex;
	}

	&:hover {
		background: #EFF8FF;
	}
}

textarea {
	resize: none;
}

.transparent-input:disabled {
	background-color: transparent;
	border: none; // Optional: remove the border if you want
	color: #000; // Change the text color if needed
	min-height: auto; // Reset the height
}

.transparent-input:enabled {
	padding: 8px;
	border-radius: 8px;
	border: 1px solid var(--Gray-300, #D0D5DD);
	background: var(--Base-White, #FFF);
	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}